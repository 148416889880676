import React from 'react'
import styled from '@xstyled/styled-components'
import CommonGrid from '@components/blocks/CommonGrid'
import { RowProps, CommonGridProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { filterSortRowsBySettings, sliceDataBySettings } from './utils'

const MinHeightBox = styled.div<{ height?: string }>`
  transition: all 1s;

  .gridWrapper {
    ${({ height }) => (height ? `height: ${height} !important;` : '')}
  }
`

type Props = {
  items?: RowProps[]
  height?: string
} & Omit<CommonGridProps, 'rowsData' | 'totalRowsCount'>

export const FormGrid = ({ items = [], isLoading, height = 'auto', ...rest }: Props): JSX.Element => {
  const { gridSelectors } = rest
  const filteredItems = React.useMemo(() => filterSortRowsBySettings(items, gridSelectors), [items, gridSelectors])
  const filteredItemsCount = filteredItems.length
  const pageItems = React.useMemo(() => sliceDataBySettings(filteredItems, gridSelectors), [filteredItems, gridSelectors])

  React.useEffect(() => {
    rest.gridActions?.clearSettings?.()
  }, []) // clear grid redux form previous expedition

  return (
    <MinHeightBox height={height}>
      <CommonGrid
        isLoading={isLoading !== undefined ? isLoading : false}
        rowsData={pageItems}
        totalRowsCount={filteredItemsCount}
        {...rest}
      />
    </MinHeightBox>
  )
}
