import {
  ExpeditionApi,
  ExpeditionApiExtended,
  ExpeditionDetail,
  ExpeditionDetailError,
  ExpeditionDetailItem,
  ExpeditionDetailTouched,
  ExpeditionDetailWrite,
  ExpeditionItemApiReadExtended,
} from '@typings/entities/Expedition'
import { WarehouseNested } from '@typings/entities/Warehouse'
import { FormikHelpers } from 'formik'
import pick from 'lodash/pick'
import { fieldNameForCommonError } from '@utils/formik/formatApiToFormikErrors'
import { t } from '@lingui/macro'
import { ApiInitialError } from '@typings/entities/Error'
import { CarrierPickupPlace, ExternalCarrierPickupPlace } from '@typings/entities/Carrier'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'
import { formatEmptyValuesToRead, formatValuesToWrite } from '@utils/formik/formatValues'
import { nanoid } from 'nanoid'
import { isExpeditionEditable, isMetaLot } from '@utils/expedition'
import { formatPriceForApi, formatPriceForRead } from '@utils/price'
import sumBy from 'lodash/sumBy'
import { Dispatch } from 'redux'
import {
  ChainedActionSubmit,
  ChainedDataSubmit,
  getFailHandler,
  savedIcorrectStateMessage,
  savedMessage,
  sentMessage,
} from '@utils/containers/advancedFormHelpers'
import { actions as expeditionsActions } from '@store/expeditions'
import { ApolloClient } from '@apollo/client'
import carrierPickupPlacesGetQuery from '@queries/carrierPickupPlacesGetQuery'
import externalCarrierPickupPlacesGetQuery from '@queries/externalCarrierPickupPlacesGetQuery'
import { displayToast } from '@utils/toast'
import { convertToISO } from '@utils/date'

const expeditionProperties = [
  'id',
  'warehouse',
  'wms',
  'orderNumber',
  'note',
  'eshop',
  'billingFirstName',
  'billingLastName',
  'billingDegree',
  'billingCompany',
  'billingStreet',
  'billingHouseNr',
  'billingZip',
  'billingState',
  'billingCity',
  'billingCountry',
  'billingEmail',
  'billingPhone',
  'billingRegistrationNumber',
  'billingVatNumber',
  'differentDeliveryAddress',
  'deliveryFirstName',
  'deliveryLastName',
  'deliveryDegree',
  'deliveryCompany',
  'deliveryStreet',
  'deliveryHouseNr',
  'deliveryZip',
  'deliveryCity',
  'deliveryCountry',
  'deliveryEmail',
  'deliveryPhone',
  'deliveryPdfFile',
  'deliveryState',
  'carrier',
  'carrierService',
  'carrierPickupPlace',
  'carrierNote',
  'trackingNumber',
  'trackingUrl',
  'externalCarrierPickupPlace',
  'externalTrackingNumber',
  'packagesCount',
  'partner',
  'value',
  'currency',
  'b2b',
  'fragile',
  'cod',
  'codValue',
  'codCurrency',
  'codVariableSymbol',
  'customerGroup',
  'items',
  'requiredExpeditionDate',
  'eshopOrderDate',
  'invoice',
  'ref1',
  'ref2',
  'ref3',
  'deliveryCost',
  'deliveryCostCurrency',
  'invoiceNumber',
  'ignoreAddressValidation',
  'carrierOptions',
  'services',
  'originalId',
]

const expeditionItemProperties = [
  'product',
  'quantity',
  'book',
  'lot',
  'lifo',
  'ref1',
  'ref2',
  'ref3',
  'bookStockAdvices',
  'productValue',
  'productValueCurrency',
]

const cloneWipeProperties = [
  'id',
  'status',
  'orderNumber',
  'invoiceUrl',
  'audits',
  'packagesCount',
  'trackingNumber',
  'trackingUrl',
  'externalTrackingNumber',
]

export const getFormPickupPlace = (pickupPlace: CarrierPickupPlace): Option<string> => ({
  value: pickupPlace.id,
  label:
    `${pickupPlace.name}` +
    (pickupPlace.address ? `, ${pickupPlace.address}` : '') +
    (pickupPlace.code ? ` (${pickupPlace.code})` : ''),
})

export const getFormExternalPickupPlace = (pickupPlace: ExternalCarrierPickupPlace): Option<string> => ({
  value: pickupPlace.id,
  label:
    (pickupPlace.street ? `${pickupPlace.street}` : '') +
    (pickupPlace.city ? `, ${pickupPlace.city}` : '') +
    (pickupPlace.zip ? `, ${pickupPlace.zip}` : '') +
    (pickupPlace.country ? `, ${pickupPlace.country}` : '') +
    (pickupPlace.code ? ` (${pickupPlace.code})` : ''),
})

export const formatInitialError = (errors: ApiInitialError[]): ExpeditionDetailError => {
  return {
    [fieldNameForCommonError]: errors,
  }
}

export const formatInitialTouched = (errors: ApiInitialError[]): ExpeditionDetailTouched => {
  const initialTouched: ExpeditionDetailTouched = {}
  for (const error of errors) {
    initialTouched[error.propertyPath] = true
  }
  return initialTouched
}

export const formatExpeditionToRead = (expedition: ExpeditionApiExtended): ExpeditionDetail => {
  const formattedExpedition: ExpeditionDetail = formatEmptyValuesToRead(expedition)
  if (formattedExpedition.value) {
    formattedExpedition.value = formatPriceForRead(formattedExpedition.value)
  }
  if (formattedExpedition.codValue) {
    formattedExpedition.codValue = formatPriceForRead(formattedExpedition.codValue)
  }
  const bookedData = {
    // we dont need to check warehouses now, because they can not be changed on existing expedition. However that can change, so we dont want to flatten this object
    // warehouse: formattedExpedition.warehouse,
    // wms: formattedExpedition.wms,
    items: {},
  }
  formattedExpedition.items = formattedExpedition.items.map((item: ExpeditionItemApiReadExtended) => {
    const newItem: ExpeditionDetailItem = formatEmptyValuesToRead(item)
    // use 'assignedLot' on noneditable expeditions, 'lot' otherwise.
    if (!isExpeditionEditable(formattedExpedition, formattedExpedition.eshopObj)) {
      newItem.lot = newItem.assignedLot || newItem.lot || (item.lifo ? '__newest' : '__oldest')
    } else {
      newItem.lot = newItem.lot || (item.lifo ? '__newest' : '__oldest')
    }
    newItem.book = item.booked || 0
    newItem.bookStockAdvices = item.bookStockAdvices || []
    newItem.bookedStockAdvices = item.bookStockAdvices || []
    newItem.bookAdviceTotal = sumBy(item?.bookStockAdvices || [], 'quantity') || 0
    newItem.bookedAdviceTotal = sumBy(item?.bookStockAdvices || [], 'quantity') || 0
    newItem.stocksState = 'loading'
    newItem.expedited = parseInt(item.expedited) || 0
    // availableTotal will be recomputed in time
    if (!bookedData.items[newItem.product.id]) bookedData.items[newItem.product.id] = {}
    bookedData.items[newItem.product.id][newItem.lot] = item.booked
    return newItem
  })
  formattedExpedition.virtualItems = formattedExpedition.removedVirtualProducts || []
  formattedExpedition.bookedData = bookedData
  if (expedition.requiredExpeditionDate) formattedExpedition.requiredExpeditionDate = new Date(expedition.requiredExpeditionDate)
  if (expedition.eshopOrderDate) formattedExpedition.eshopOrderDate = new Date(expedition.eshopOrderDate)
  if (expedition.invoice?.name)
    formattedExpedition.deliveryPdfFile = {
      ...expedition.invoice,
      name: expedition.invoice.originalName,
      type: expedition.invoice.mimeType,
    }
  return formattedExpedition
}

export const formatExpeditionToClone = (expedition: ExpeditionApiExtended): ExpeditionDetail => {
  const formattedExpedition = formatExpeditionToRead(expedition)
  // delete fields that need to be deleted
  cloneWipeProperties.forEach((f) => delete formattedExpedition[f])
  // for clone
  formattedExpedition.originalId = expedition.id
  // wipe selected lots out of items
  formattedExpedition.items = formattedExpedition.items.reduce<ExpeditionDetailItem[]>((acc, item) => {
    const foundIndex = acc.findIndex((hayItem) => hayItem.product.id == item.product.id)
    if (foundIndex >= 0) {
      acc[foundIndex].quantity += item.quantity
    } else {
      // nonCloneItemProperties.forEach((f) => delete item[f])
      acc.push({
        id: `${nanoid()}`,
        quantity: item.quantity,
        product: item.product,
        ref1: '',
        ref2: '',
        ref3: '',
        lot: '__oldest',
        lifo: false,
        book: 0,
        availableTotal: 0, // availableTotal will be recomputed in time
        stocksState: 'loading',
        bookStockAdvices: [],
        bookAdviceTotal: 0,
        bookedAdviceTotal: 0,
      })
    }
    return acc
  }, [])

  return formattedExpedition
}

const formatExpeditionToWrite = (values, warehouses: WarehouseNested[]): ExpeditionDetailWrite => {
  const formattedExpedition = formatValuesToWrite(values, expeditionProperties)
  if (formattedExpedition.value) {
    formattedExpedition.value = formatPriceForApi(formattedExpedition.value)
  }
  if (!formattedExpedition.cod) {
    formattedExpedition.codValue = null
    formattedExpedition.codCurrency = null
    formattedExpedition.codVariableSymbol = null
  }
  if (formattedExpedition.cod && !formattedExpedition.codCurrency) {
    formattedExpedition.codCurrency = formattedExpedition.currency
  }
  if (formattedExpedition.codValue) {
    formattedExpedition.codValue = formatPriceForApi(formattedExpedition.codValue)
  }
  formattedExpedition.items = formattedExpedition.items.map((item) => {
    const newItem = formatValuesToWrite(item, expeditionItemProperties)
    newItem.product = item.product.id
    if (isMetaLot(newItem.lot)) {
      newItem.lifo = !!(newItem.lot === '__newest')
      newItem.lot = null
    }
    newItem.bookStockAdvices = item.bookStockAdvices?.map((booking) => pick(booking, ['stockAdviceItem', 'quantity'])) || []
    return newItem
  })
  if (formattedExpedition.requiredExpeditionDate) {
    formattedExpedition.requiredExpeditionDate = convertToISO(formattedExpedition.requiredExpeditionDate)
  }
  if (formattedExpedition.eshopOrderDate) {
    formattedExpedition.eshopOrderDate = convertToISO(formattedExpedition.eshopOrderDate)
  }
  return formattedExpedition
}

const DELIVERY_ADDRESS_ERROR = '3e80f864-5a81-4a5e-811a-67328b1369fb' // hardcoded on backend
const getHiddenError = (expedition: ExpeditionApi): string | false => {
  if (expedition?.status === 'incorrect') {
    if (expedition.errors?.find((error) => error?.code == DELIVERY_ADDRESS_ERROR)) {
      return t({ id: 'form.addressValidation.error', message: 'Address did not pass validation' })
    } else {
      return expedition.errors?.map((error: Error) => error.message).join('\n') || ''
    }
  }
  return false
}

/**
 * Save and send actions are different in expedition then in advice/tranfers because of 'hidden errors' => saved in incorrect state,
 * this is cleaner than messing up universal useAdvancedSubmit a useActionSubmit, but if more entities needed that, it would be worth paremetrizing it with getHiddenError / onHiddenError
 */

export const getOnSave =
  (dispatch: Dispatch, onSuccess: (formikHelpers: FormikHelpers<any>) => void, warehouses): ChainedDataSubmit<ExpeditionDetail> =>
  (values, formikHelpers, isChained): Promise<string | void> => {
    const action = values?.id ? expeditionsActions.editExpedition : expeditionsActions.createExpedition
    const handleFail = getFailHandler(formikHelpers, displayToast, true)

    return dispatch(action(formatExpeditionToWrite(values, warehouses)))
      .promise.then(({ data }: { data: ExpeditionApi }) => {
        const hiddenError = getHiddenError(data)
        if (!isChained) {
          formikHelpers.setSubmitting(false)
          if (hiddenError) {
            displayToast({ type: 'warning', title: savedIcorrectStateMessage(), text: hiddenError })
          } else {
            displayToast({ type: 'success', text: savedMessage() })
          }
          onSuccess && onSuccess(formikHelpers)
        }
        return hiddenError ? null : data.id
      })
      .catch(handleFail)
  }

export const getOnSend =
  (dispatch: Dispatch, onSuccess: (formikHelpers: FormikHelpers<any>) => void): ChainedActionSubmit<ExpeditionDetail> =>
  ({ id }, formikHelpers, isChained) => {
    const handleFail = getFailHandler(formikHelpers, displayToast, true)
    return dispatch(expeditionsActions.sendToWms({ id }))
      .promise.then(({ data }: { data: ExpeditionApi }) => {
        formikHelpers.setSubmitting(false)
        const hiddenError = getHiddenError(data)
        if (hiddenError) {
          displayToast({ type: 'warning', title: savedIcorrectStateMessage(), text: hiddenError })
        } else {
          displayToast({ type: 'success', text: isChained ? savedMessage() : sentMessage() })
        }
        onSuccess && onSuccess(formikHelpers)
      })
      .catch(handleFail)
  }

export const onSearchPickupPlace =
  (client: ApolloClient<any>, setPickupPlacesData: React.Dispatch<React.SetStateAction<any>>) =>
  async (
    text: string,
    byId: boolean,
    country: string,
    carrier: string,
    pickupType: string,
    validatePickupPlaceCountry: boolean,
  ) => {
    const limit = 100
    const status = 1
    let criteria
    if (byId) {
      criteria = { id: { eq: text } }
    } else {
      if (!country || !carrier) return []
      if (pickupType) {
        criteria = {
          country: { eq: country },
          carrier: { eq: carrier },
          status: { eq: status },
          type: { in: pickupType },
        }
      }
      criteria = {
        carrier: { eq: carrier },
        status: { eq: status },
        ...(validatePickupPlaceCountry ? { country: { eq: country } } : {}),
      }
      if (text) {
        criteria.OR = [{ name: { like: `%${text}%` } }, { address: { like: `%${text}%` } }, { code: { like: `%${text}%` } }]
        // if number
        if (/^[0-9]+$/.test(text.trim())) {
          criteria.OR.push({ code: { eq: `${text.trim()}` } })
        }
      }
    }

    return await client.query({ query: carrierPickupPlacesGetQuery, variables: { limit, criteria } }).then(({ data }) => {
      const results = data?.carrierPickupPlacesGet?.results || []

      setPickupPlacesData(results)

      const options = results.map((item) => {
        return getFormPickupPlace(item)
      })
      return options
    })
  }

export const onSearchExternalPickupPlace =
  (client: ApolloClient<any>) => async (text: string, byId: boolean, country: string, carrierPickupPlace: string) => {
    let criteria

    if (byId) {
      criteria = { id: { eq: text } }
    } else {
      if (!carrierPickupPlace) {
        return []
      }
      criteria = {
        carrierPickupPlace: { eq: carrierPickupPlace },
        country: { eq: country },
        status: { eq: 1 },
      }

      if (text) {
        criteria.OR = [{ street: { like: `%${text}%` } }, { code: { like: `%${text}%` } }]
        // if number
        if (/^[0-9]+$/.test(text.trim())) {
          criteria.OR.push({ code: { eq: `${text.trim()}` } })
        }
      }
    }
    return await client.query({ query: externalCarrierPickupPlacesGetQuery, variables: { criteria } }).then(({ data }) => {
      const results = data?.externalCarrierPickupPlacesGet?.results || []

      const options = results.map((item) => {
        return getFormExternalPickupPlace(item)
      })
      return options
    })
  }
