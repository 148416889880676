import { t } from '@lingui/macro'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export enum IReturnReceiptStatus {
  New = 'new',
  InProgress = 'in_progress',
  Approved = 'approved',
  Rejected = 'rejected',
  Refunded = 'refunded',
  Closed = 'closed',
}

export const returnsClientStatusTrans: Record<IReturnReceiptStatus, () => string> = {
  new: () => t({ id: 'returnsClientStatus.new', message: `New` }),
  in_progress: () => t({ id: 'returnsClientStatus.inProgress', message: `In progress` }),
  approved: () => t({ id: 'returnsClientStatus.approved', message: `Approved` }),
  rejected: () => t({ id: 'returnsClientStatus.rejected', message: `Rejected` }),
  refunded: () => t({ id: 'returnsClientStatus.refunded', message: `Refunded` }),
  closed: () => t({ id: 'returnsClientStatus.closed', message: `Closed` }),
}

export const returnReceiptStatusTrans = {
  new: () => t({ id: 'returnReceiptStatus.new', message: `New` }),
  inventory: () => t({ id: 'returnReceiptStatus.inventory', message: `Inventory` }),
  returned: () => t({ id: 'returnReceiptStatus.returned', message: `Returned` }),
  returnedDamaged: () => t({ id: 'returnReceiptStatus.returnedDamaged', message: `Returned damaged` }),
  returnedOpen: () => t({ id: 'returnReceiptStatus.returnedOpen', message: `Returned open` }),
}

export const returnsClientStatusToColor: Record<IReturnReceiptStatus, FlagAppearanceType> = {
  new: 'tealSubtle',
  in_progress: 'blueSubtle',
  approved: 'purpleSubtle',
  rejected: 'redBold',
  refunded: 'greenSubtle',
  closed: 'yellowSubtle',
}
