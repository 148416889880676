/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { Trans } from '@lingui/react'
import DatePickerField from '@components/forms/components/DatePickerField'
import TextAreaField from '@components/forms/components/TextAreaField'
import AddressField from './AddressField'
import React, { useCallback, useState } from 'react'
import { Col } from '@designSystem/Grid'
import SelectField from '@components/forms/components/SelectField'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { ExpeditionDetail } from '@typings/entities/Expedition'
import Button from '@mailstep/design-system/ui/Elements/Button'
import InvoiceAttachmentDialog from './InvoiceAttachmentDialog'
import InvoiceAttachmentDetail from './InvoiceAttachmentDetail'
import SpaceAroundWrap from '@mailstep/design-system/ui/Elements/SpaceAround'
import Fieldset from '@designSystem/Fieldset'
import { x } from '@xstyled/styled-components'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import SwitchField from '@components/forms/components/SwitchField'
import { SelectOptionCrossdock } from '../helpers'
import PartnerInputField from './PartnerInputField'
import { ResponsiveRowWrap, ResponsiveSectionWrap, SwitchFieldContainer } from '@components/forms/styles'

type Props = {
  eshops?: SelectOption[]
  warehouses?: SelectOption[]
  wmses?: SelectOptionCrossdock
  countries: SelectOption[]
  partners: SelectOption[]
  deliveryPdfFile: ExpeditionDetail['deliveryPdfFile']
  partner: ExpeditionDetail['partner']
  differentDeliveryAddress: ExpeditionDetail['differentDeliveryAddress']
  hasEshop: boolean
  hasWarehouse: boolean
  onCountryChange: Function
  onPartnerChange: Function
  onInvoiceDownload: () => Promise<any>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  isEditable: boolean
  isCreatingNew: boolean
  lockBillingAddress: boolean
  lockDeliveryAddress: boolean
  expeditionServiceOptions: SelectOption[]
}

const OrderDetails = ({
  eshops,
  warehouses,
  wmses,
  countries,
  partners,
  differentDeliveryAddress,
  hasEshop,
  hasWarehouse,
  onCountryChange,
  onInvoiceDownload,
  onPartnerChange,
  isEditable,
  isCreatingNew,
  setFieldValue,
  deliveryPdfFile,
  lockBillingAddress,
  lockDeliveryAddress,
  expeditionServiceOptions,
}: Props): JSX.Element => {
  const [isInvoiceAttachmentOpen, setIsInvoiceAttachmentOpen] = useState(false)
  const handleCloseInvoiceAttachment = useCallback(() => setIsInvoiceAttachmentOpen(false), [])
  const handleOpenInvoiceAttachment = useCallback(() => setIsInvoiceAttachmentOpen(true), [])
  const isEditableAndCreating = isEditable && isCreatingNew

  return (
    <ResponsiveSectionWrap>
      <InvoiceAttachmentDialog
        name="deliveryPdfFile"
        setFieldValue={setFieldValue}
        isOpen={isInvoiceAttachmentOpen}
        onClose={handleCloseInvoiceAttachment}
      />
      <Col size={6} mr={3}>
        <StyledSubTitle>
          <Trans id="form.orderDetails.heading" message="Order details" />
        </StyledSubTitle>
        <ResponsiveRowWrap id="orderDetails">
          <Field
            component={SelectField}
            name="eshop"
            options={eshops}
            label={<Trans id="form.eshop.label" message="Eshop" />}
            spaceAround
            disabled={!isEditableAndCreating}
          />
          <Field
            component={DatePickerField}
            name="requiredExpeditionDate"
            label={<Trans id="form.requiredExpeditionDate.label" message="Required date of expedition" />}
            spaceAround
            disabled={!isEditable}
            icon="calendarPlus"
            allowClear={true}
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap id="orderDetails">
          <Field
            component={SelectField}
            name="warehouse"
            options={warehouses}
            label={<Trans id="form.warehouse.label" message="Virtual warehouse" />}
            spaceAround
            disabled={!isEditableAndCreating}
            isLocked={!hasEshop}
            lockedText={<Trans id="form.warehouse.notReady" message="Select an eshop first..." />}
          />
          <Field
            component={DatePickerField}
            name="eshopOrderDate"
            label={<Trans id="form.eshopOrderDate.label" message="Eshop order date" />}
            spaceAround
            disabled={!isEditable}
            icon="calendarPlus"
            allowClear={true}
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap id="orderDetails">
          <Col>
            <Field
              component={SelectField}
              name="wms"
              options={wmses}
              label={<Trans id="form.wms.label" message="Physical warehouse" />}
              spaceAround
              disabled={!isEditableAndCreating}
              isLocked={!hasEshop || !hasWarehouse}
              lockedText={<Trans id="form.wms.notReady" message="Select a warehouse first..." />}
            />
          </Col>
          <Col>
            <Field
              component={InputField}
              name="customerGroup"
              label={<Trans id="form.customerGroup.label" message="Customer group" />}
              spaceAround
              disabled={!isEditable}
            />
          </Col>
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <Col size={6}>
            <Field
              component={SelectField}
              name="partner"
              options={partners}
              label={<Trans id="form.partner.label" message="Partner" />}
              spaceAround
              onValueChange={onPartnerChange}
              isClearable
              disabled={!isEditable}
              isLocked={!hasEshop}
              lockedText={<Trans id="form.partner.notReady" message="Select an eshop first..." />}
            />
          </Col>
        </ResponsiveRowWrap>
        <ResponsiveRowWrap display="flex" justifyContent="space-between">
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="ref1"
                label={<Trans id="dataGrid.columnRef1.title" message="Ref 1" />}
                disabled={!isEditable}
                spaceAround
              />
            </x.div>
          </Col>
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="ref2"
                label={<Trans id="dataGrid.columnRef2.title" message="Ref 2" />}
                disabled={!isEditable}
                spaceAround
              />
            </x.div>
          </Col>
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="ref3"
                label={<Trans id="dataGrid.columnRef3.title" message="Ref 3" />}
                disabled={!isEditable}
                spaceAround
              />
            </x.div>
          </Col>
        </ResponsiveRowWrap>
        <ResponsiveRowWrap display="flex" justifyContent="space-between">
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="deliveryCost"
                disabled={!isEditable}
                label={<Trans id="dataGrid.deliveryCost.title" message="Delivery cost" />}
                spaceAround
              />
            </x.div>
          </Col>
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="deliveryCostCurrency"
                disabled={!isEditable}
                label={<Trans id="dataGrid.deliveryCostCurrency.title" message="Delivery cost currency" />}
                spaceAround
              />
            </x.div>
          </Col>
          <Col size={2}>
            <x.div>
              <Field
                component={InputField}
                name="invoiceNumber"
                disabled={!isEditable}
                label={<Trans id="dataGrid.invoiceNumber.title" message="Invoice number" />}
                spaceAround
              />
            </x.div>
          </Col>
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <Col size={6}>
            <Field
              component={SelectField}
              name="services"
              options={expeditionServiceOptions}
              isMulti
              disabled={!isEditable}
              label={<Trans id="dataGrid.orderService.title" message="Order service" />}
              spaceAround
            />
            <Field
              component={TextAreaField}
              name="note"
              label={<Trans id="form.note.label" message="Note / Description" />}
              spaceAround
              disabled={!isEditable}
            />
          </Col>
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <SpaceAroundWrap>
            <x.div mt={3}>
              <StyledSubTitle>{<Trans id="form.pdfAttachment.label" message="PDF attachment" />}</StyledSubTitle>
            </x.div>
            <x.div display="flex" flexDirection="row" alignItems="center">
              {isEditable && (
                <x.div flexGrow={0}>
                  <Button
                    type="button"
                    variant="default"
                    appearance="secondary"
                    onClick={handleOpenInvoiceAttachment}
                    as="div"
                    iconLeft="plus"
                  >
                    <Trans id="form.buttonAttachInvoice" message="Attach invoice" />
                  </Button>
                </x.div>
              )}
              <x.div flexGrow={1}>
                <InvoiceAttachmentDetail
                  isDisabled={!isEditable}
                  deliveryPdfFile={deliveryPdfFile}
                  onInvoiceDownload={onInvoiceDownload}
                  setFieldValue={setFieldValue}
                />
              </x.div>
            </x.div>
          </SpaceAroundWrap>
        </ResponsiveRowWrap>
      </Col>
      <Col size={6}>
        <StyledSubTitle>
          <Trans id="form.billingAddress.heading" message="Billing address" />
        </StyledSubTitle>
        <Fieldset disabled={!isEditable} fullWidth>
          <AddressField
            name="billing"
            countries={countries}
            onCountryChange={onCountryChange}
            isEditable={isEditable}
            lockAddress={lockBillingAddress}
          />
          <ResponsiveRowWrap>
            <Col>
              <Field
                component={PartnerInputField}
                name="billingRegistrationNumber"
                label={<Trans id="form.registrationNumber.label" message="Business ID" />}
                spaceAround
              />
            </Col>
            <Col>
              <Field
                component={PartnerInputField}
                name="billingVatNumber"
                label={<Trans id="form.vatNumber.label" message="VAT" />}
                spaceAround
              />
            </Col>
          </ResponsiveRowWrap>
        </Fieldset>

        <ResponsiveRowWrap>
          <Col>
            <Field
              component={SwitchField}
              name="differentDeliveryAddress"
              label={<Trans id="form.differentDeliveryAddress.label" message="Delivery address different from billing address" />}
              disabled={!isEditable}
              spaceBetween
            />
          </Col>
        </ResponsiveRowWrap>

        {differentDeliveryAddress && (
          <Fieldset disabled={!isEditable} fullWidth>
            <StyledSubTitle>
              <Trans id="form.deliveryAddress.heading" message="Delivery address" />
            </StyledSubTitle>
            <x.div id="deliveryAddress">
              <AddressField
                name="delivery"
                countries={countries}
                onCountryChange={onCountryChange}
                isEditable={isEditable}
                lockAddress={lockDeliveryAddress}
              />
            </x.div>
          </Fieldset>
        )}
      </Col>
    </ResponsiveSectionWrap>
  )
}

OrderDetails.defaultProps = {
  warehouses: [],
  wmses: [],
}

export default React.memo(OrderDetails)
