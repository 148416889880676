import { StockMovement, StockMovementSimple } from '@typings/entities/StockMovement'

export const formatReceiptMovement = (movement: StockMovement): StockMovementSimple => ({
  id: movement.id,
  product: {
    id: movement.product.id,
    productSku: movement.product.productSku,
    internalSku: movement.product.internalSku,
    name: movement.product.name,
    referenceNumbers: movement.product.referenceNumbers,
  },
  lot: movement.lot,
  quantity: movement.quantity,
  serialNumbers: movement.serialNumbers,
  movementSubType: movement.movementSubType,
})
